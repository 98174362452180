/* eslint-disable import/prefer-default-export */
export const optionsCatalog = (t) => [
    {
        aclCode: 'header_product',
        key: 'catalog',
        label: t('menu:menuCatalog:parent'),
        children: [
            {
                aclCode: 'oms_lite_product_list',
                key: 'productlist',
                label: t('menu:menuCatalog:products'),
                url: '/product/productlist',
            },
            {
                aclCode: 'product_bundle',
                key: 'productbundle',
                label: t('menu:menuCatalog:productBundle'),
                url: '/product/productbundle',
            },
            {
                aclCode: 'oms_lite_product_bin',
                key: 'productbin',
                label: t('menu:menuCatalog:productBin'),
                url: '/product/productbin',
            },
            {
                aclCode: 'product_bulk_tools',
                key: 'productbulktools',
                label: t('menu:menuCatalog:productBulkTools'),
                url: '/product/productbulktools',
            },
            {
                aclCode: 'oms_marketplace_product_duplicate',
                key: 'productduplicate',
                label: t('menu:menuCatalog:productDuplicate'),
                url: '/product/productduplicate',
            },
            {
                aclCode: 'oms_marketplace_product_duplicate',
                key: 'productremotevariant',
                label: t('menu:menuCatalog:productRemoteVariant'),
                url: '/product/productremotevariant',
            },
        ],
    },
];
