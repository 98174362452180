/* eslint-disable import/prefer-default-export */
export const optionsInventory = (t) => [
    {
        aclCode: 'oms_lite_header_catalog_inventory',
        key: 'inventory',
        label: t('menu:menuInventory:parent'),
        children: [
            {
                aclCode: 'oms_lite_source',
                key: 'source',
                label: t('menu:menuInventory:manageStock'),
                url: '/cataloginventory/managestock',
            },
            {
                aclCode: 'oms_lite_override_stock',
                key: 'overridestock',
                label: t('menu:menuInventory:overrideStock'),
                url: '/cataloginventory/overridestock',
            },
            {
                aclCode: 'stock_summary',
                key: 'stocksummary',
                label: t('menu:menuInventory:channelStockSummary'),
                url: '/cataloginventory/channelstocksummary',
            },
            {
                aclCode: 'oms_lite_stock_transfer',
                key: 'stocktransfer',
                label: t('menu:menuInventory:stockTransfer'),
                url: '/cataloginventory/stocktransfer',
            },
            {
                aclCode: 'inventory_adjustment_dashboard',
                key: 'stockadjustment',
                label: t('menu:menuInventory:stockAdjustment'),
                url: '/cataloginventory/stockadjustment',
            },
            {
                aclCode: 'oms_lite_location_price_upload',
                key: 'locationpriceupload',
                label: t('menu:menuInventory:managePrice'),
                url: '/cataloginventory/locationpriceupload',
            },
            {
                aclCode: 'oms_lite_tools_history',
                key: 'toolshistory',
                label: t('menu:menuInventory:updateStockHistory'),
                url: '/tools/history',
            },
            {
                aclCode: 'inventory_audit_trail',
                key: 'inventoryaudittrail',
                label: t('menu:menuInventory:inventoryAuditTrail'),
                url: '/cataloginventory/inventoryaudittrail',
            },
        ],
    },
];
